<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.title.value"
          :disabled="loading"
          :label="$t('layout.form.titleInput')"
          :error="!!form.title.errors.length"
          :error-messages="translatedErrors(form.title.errors)"
          @blur="validate('title')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="prayer-date"
          :label="$t('layout.form.dateInput')"
          :config="dateConfig"
          v-model="prayerDate"
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedStatus"
          :label="$t('layout.form.status')"
          :options="statusList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
        />
      </div>
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.request.value"
          type="textarea"
          :autosize="true"
          :min-rows="5"
          :disabled="loading"
          :label="$t('layout.form.requestInput')"
          :error="!!form.request.errors.length"
          :error-messages="translatedErrors(form.request.errors)"
          @blur="validate('request')"
        />
      </div>
      <div class="flex xs12">
        <va-input
          v-if="prayer.id"
          color="info"
          v-model="form.observations.value"
          type="textarea"
          :autosize="true"
          :min-rows="5"
          :disabled="loading"
          :label="$t('layout.form.observationsInput')"
          :error="!!form.observations.errors.length"
          :error-messages="translatedErrors(form.observations.errors)"
          @blur="validate('observations')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'

export default {
  name: 'prayers-form',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    prayer: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      prayerDate: null,
      selectedStatus: '',
      dateConfig: {
        min: null,
        max: null,
      },
      form: {
        title: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        request: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        observations: {
          value: '',
          validate: {
            required: false,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale', 'currentUser']),
    statusList () {
      return [
        { name: this.$t('prayers.status.pending'), id: 0 },
        { name: this.$t('prayers.status.answered'), id: 1 },
      ]
    },
  },
  watch: {
    prayer (val) {
      this.setPrayer(val)
    },
    async currentLocale (val) {
      await this.$nextTick()
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setPrayer (val) {
      this.setFormData(val)
      if (val.date) {
        this.prayerDate = val.date
      }

      const currentStatus = val.status || 0
      const status = this.statusList.find(x => x.id === currentStatus)
      this.selectedStatus = status

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.prayer) {
        this.setPrayer(this.prayer)
      }
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const prayer = this.getFormData(this.prayer)
      if (this.selectedStatus) {
        prayer.status = this.selectedStatus.id
      }

      if (this.prayerDate) {
        prayer.date = this.prayerDate
      }

      if (!prayer.user_id) {
        prayer.user_id = this.currentUser.id
      }
      if (!prayer.district_id && this.currentUser.district) {
        prayer.district_id = this.currentUser.district.id
      }
      if (!prayer.country_id && this.currentUser.country) {
        prayer.country_id = this.currentUser.country.id
      }
      if (!prayer.region_id && this.currentUser.region) {
        prayer.region_id = this.currentUser.region.id
      }

      delete prayer.user
      delete prayer.district
      delete prayer.country
      delete prayer.region
      this.$emit('submit', prayer)
    },
  },
}
</script>
